import { FC, useEffect, useState, useCallback } from 'react';
import { getSitesByOrga, deleteSiteById } from '../../services/Api';
import { useNavigate } from 'react-router-dom';
import { PageTitle, usePageData } from '../../../_metronic/layout/core';
import { Button, Modal } from 'react-bootstrap';
import { Toast } from 'bootstrap';

interface SitesListProps {
    orgaId: number;
}

interface Site {
    id: number;
    name: string;
    url: string;
    audits: { id: number, name: string, status: string }[]
}

const SitesList: FC<SitesListProps> = ({ orgaId }) => {
    const [sites, setSites] = useState<Site[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { setPageBreadcrumbs } = usePageData();
    const [showToast, setShowToast] = useState(false); 
    const [showModal, setShowModal] = useState(false);

    const deleteSite = async (siteId: number) => {
        try {
            await deleteSiteById(siteId); // Appel à l'API
        } catch (error) {
            throw new Error('Erreur lors de la suppression.');
        }
    };

    const fetchSites = useCallback(async () => {
        try {
          const sites = await getSitesByOrga(orgaId);
          setSites(sites);
        } catch {
          setError('Erreur lors de la récupération des sites.');
        } finally {
          setLoading(false);
        }
    }, [orgaId]);
    
    useEffect(() => {
        fetchSites();
    }, [fetchSites]);

    useEffect(() => {
        setPageBreadcrumbs([
            { title: 'Tableau de bord', path: '/tableau-de-bord', isActive: false },
            { title: 'Sites', path: '', isActive: true },
        ]);
    }, [setPageBreadcrumbs]);

    useEffect(() => {
        if (showToast) {
            const toastEl = document.getElementById('deleteToast');
            if (toastEl) {
                const toast = new Toast(toastEl);
                toast.show();

                // Masquer le toast après 3 secondes
                const timer = setTimeout(() => {
                    toast.hide();
                    setShowToast(false); // Réinitialise l'état du toast
                }, 3000);

                return () => clearTimeout(timer); // Nettoyage du timer
            }
        }
      }, [showToast]);

    const [siteToDelete, setSiteToDelete] = useState<Site | null>(null);

    const handleShowModal = (siteId: number) => {
        const selectedSite = sites.find(site => site.id === siteId);
        setSiteToDelete(selectedSite || null);
        setShowModal(true);
    };

    const handleDel = async () => {
        if (siteToDelete) {
            try {
                // Effectuer la suppression via l'API (remplacer par votre propre fonction de suppression)
                await deleteSite(siteToDelete.id); 
    
                // Mettre à jour l'état pour supprimer le site de la liste
                setSites(sites.filter(site => site.id !== siteToDelete.id));
    
                // Fermer le modal
                setShowModal(false);
                setShowToast(true); // Déclenche l'affichage du toast
            } catch (error) {
                setError('Erreur lors de la suppression du site.');
            }
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <PageTitle breadcrumbs={[
                { title: 'Tableau de bord', path: '/tableau-de-bord', isActive: true },
            ]}  />
            <div className="card-header d-flex justify-content-between align-items-center mb-4">
                <h3 className="card-title">Mes sites suivis</h3>
                <button
                    type="button"
                    className="btn btn-primary hover-elevate-up"
                    onClick={() => navigate('/sites/nouveau')}
                >
                    Ajouter un site
                </button>
            </div>
            <div className="row mt-10">
                {sites.length > 0 ? (
                    sites.map((site) => (
                    <div key={site.id} className="col-md-4 mb-4 d-flex align-items-stretch">
                        <div className="card flex-fill">
                            <div className="card-body d-flex flex-column">
                                <div className='d-flex align-items-center justify-content-between flex-row'>
                                    <h5 className="card-title">{site.name}</h5>
                                    <div className='' 
                                        onClick={() => handleShowModal(site.id)}>
                                        <i className="ki-duotone ki-trash-square text-danger fs-2x cursor-pointer hover-elevate-up">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                            <span className="path3"></span>
                                            <span className="path4"></span>
                                        </i>
                                    </div>
                                </div>
                                
                                <p className="card-text">
                                    <span>Domaine :</span> <a href={site.url} target="_blank" rel="noopener noreferrer">{site.url}</a>
                                </p>
                                <div className="flex-grow-1">
                                {site.audits && site.audits.length > 0 ? (
                                    <div>
                                    {site.audits.filter(audit => audit.status === 'En cours').length > 0 && (
                                        <div className="d-flex justify-content-between align-items-center">
                                            Audits en cours
                                            <span className="badge text-bg-warning rounded-pill">{site.audits.filter(audit => audit.status === 'En cours').length}</span>
                                        </div>
                                    )}
                                    {site.audits.filter(audit => audit.status === 'Init').length > 0 && (
                                        <div className="d-flex justify-content-between align-items-center mt-4">
                                            Audits initiés
                                            <span className="badge text-bg-secondary rounded-pill">{site.audits.filter(audit => audit.status === 'Init').length}</span>
                                        </div>
                                    )}
                                    {site.audits.filter(audit => audit.status === 'Terminé').length > 0 && (
                                        <div className="d-flex justify-content-between align-items-center mt-4">
                                            Audits terminés
                                            <span className="badge text-bg-success rounded-pill">{site.audits.filter(audit => audit.status === 'Terminé').length}</span>
                                        </div>
                                    )}
                                    </div>
                                ) : (
                                    <p>Aucun audit disponible</p>
                                )}
                                </div>
                                <div className="separator my-5 border-dark"></div>
                                <a
                                    href={`/sites/audits/${site.id}`}
                                    className="btn btn-link hover-elevate-up"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(`/sites/audits/`, { state: { siteId: site.id, domain: site.url } });
                                    }}
                                    >
                                    Accéder à mes audits
                                </a>
                            </div>
                        </div>
                    </div>
                    ))
                ) : (
                    <div className="text-center">Aucun site suivi</div>
                )}
            </div>

             {/* Toast Notification */}
            <div id="kt_docs_toast_stack_container" className="toast-container position-fixed top-0 end-0 p-3 z-index-3">
                <div id="deleteToast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="toast-header bg-success text-white">
                        <strong className="me-auto">Succès</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div className="toast-body bg-success text-white">
                       Le domaine a bien été supprimé.
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation de suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Êtes-vous sûr de vouloir supprimer le domaine {siteToDelete?.name} ?
                    Cette action vous fera perdre tout les audits associés à ce site.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="danger" onClick={handleDel}>
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default SitesList;