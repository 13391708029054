import React from 'react';
import { usePageData } from '../../_metronic/layout/core/PageData';
import { Link } from 'react-router-dom';

const Breadcrumb: React.FC = () => {
    const { pageBreadcrumbs } = usePageData();

    return (
        <ol className="breadcrumb text-muted fs-6 fw-semibold mb-6">
            {pageBreadcrumbs?.map((breadcrumb, index) => (
            <li key={index} className={`breadcrumb-item ${breadcrumb.isActive ? 'text-muted' : ''}`}>
                {breadcrumb.isActive ? (
                breadcrumb.title
                ) : (
                <Link to={breadcrumb.path} state={breadcrumb.state} className="opacity-50-hover">
                    {breadcrumb.title}
                </Link>
                )}
            </li>
            ))}
        </ol>   
    );
};

export default Breadcrumb;