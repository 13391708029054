export const CadresService = {
    async getNextQuestion(response: string) {

        if(response === "Oui"){
            return {test_id: 61, response: 1, message: `Le contenu de l'attribut title est pertinent.`};
        } else {
            return {test_id: 61, response: -1, message: `Le contenu de l'attribut title n'est pas pertinent.`};
        }

    }
};