// services/ImagesService.ts
export const ImagesService = {
  async getNextQuestion(response: string, currentAttribute: any) {
    const isDecorative = response === "Décorative";
    const isInformative = response === "Porteuse d'information";
    const fileName = currentAttribute.src.split('/').pop();

    if (isInformative) {
      // Si l'image est porteuse d'information, vérifier la présence d'une alternative textuelle
      const hasAltText = currentAttribute.alt && currentAttribute.alt !== "";
      const hasAriaLabel = currentAttribute.aria_label && currentAttribute.aria_label !== "";
      const hasAriaLabelledBy = currentAttribute.aria_labelledby && currentAttribute.aria_labelledby !== "";
      const hasTitle = currentAttribute.title && currentAttribute.title !== "";

      if (hasAltText || hasAriaLabel || hasAriaLabelledBy || hasTitle) {
        const questionWithTestId15 = currentAttribute.questions.find((question: any) => question.test_id === 15);

        if (questionWithTestId15) {
          const attributes = ['alt', 'aria_label', 'aria_labelledby', 'title']
            .filter(attr => currentAttribute[attr] && currentAttribute[attr] !== "")
            .reduce((acc, attr) => {
              acc[attr] = currentAttribute[attr];
              return acc;
            }, {});
            questionWithTestId15.question += `\n ${Object.entries(attributes).map(([key, value]) => `${key}="${value}"`).join('\n')}`;

          return { relatedQuestion: questionWithTestId15, test_id: 1, response: 1, message: `L'image "${fileName}" est porteuse d'information et contient une alternative textuelle` };
        }
      } else {
        // L'image informative n'a pas d'alternative textuelle
        return {test_id: 1, response: -1, message: `L'image "${fileName}" est porteuse d'information mais n'a pas d'alternative textuelle.`};
      }
    }

    if (isDecorative) {
      // Si l'image est décorative, vérifier que l'attribut alt est vide et présence de aria-hidden ou role="presentation"
      const hasEmptyAlt = currentAttribute.alt === "";
      const hasAriaHiddenOrRolePres = currentAttribute.aria_hidden === true || currentAttribute.role === "presentation";
      if (hasEmptyAlt || hasAriaHiddenOrRolePres) {
        return {test_id: 9, response: 1, message: `L'image "${fileName}" est décorative, l'attribut alt est vide et aria-hidden ou role="presentation" est présent.`};
      } else {
        return {test_id: 9, response: -1, message: `L'image "${fileName}" est défini comme décorative mais l'attribut alt n'est pas vide ou aria-hidden / role="presentation" n'est pas présent.`};
      }
    }

    else{
      if(response === "Oui") {
        return {test_id: 15, response: 1, message: `L'image "${fileName}" est porteuse d'information et les attributs sont pertinents.`};
      } else {
        return {test_id: 15, response: -1, message: `L'image "${fileName}" est porteuse d'information mais les attributs ne sont pas pertinents.`};
      }
    }
  },
};
