import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getDecisionsQuestions, sendDecisionResponse } from "../../../../../services/Api"; // Assurez-vous que le chemin est correct
import { QuestionsServices } from "../../../../../services/questions-services/QuestionsService";
import { usePageData } from "../../../../../../_metronic/layout/core";

interface AideDecisionLocationState {
    siteId: number;
    auditId: number;
    auditName: string;
    auditStatus: string;
    domain: string;
}

const AideDecisions: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as AideDecisionLocationState;
    const { auditId, auditName, auditStatus, siteId, domain } = state || {};
    const { setPageBreadcrumbs } = usePageData();

    const [currentAttributeIndex, setCurrentAttributeIndex] = useState<number>(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [attributes, setAttributes] = useState<any[]>([]); // Contient les attributs avec les questions
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [responses, setResponses] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<string | null>(null); // Ajouté pour suivre l'option sélectionnée
    const groupRef = useRef<string>("");
    const [url_id, setUrlId] = useState<number>(0);
    const [pageName, setPageName] = useState<string>("");

    const currentAttribute = attributes[currentAttributeIndex];
    const currentQuestion = currentAttribute?.questions?.[currentQuestionIndex];

    useEffect(() => {
        if (!state) {
            navigate("/tableau-de-bord");
            return;
        }

        setPageBreadcrumbs([
            { title: "Tableau de bord", path: "/tableau-de-bord", isActive: false },
            { title: "Audits", path: `/sites/audits`, isActive: false, state: { siteId } },
            { title: "Synthèse", path: `/sites/audits/synthese`, isActive: false, state: { siteId, auditId, auditName, auditStatus, domain } },
            { title: "Aide à la décision", path: "", isActive: true },
        ]);
    }, [setPageBreadcrumbs, siteId, auditId, auditName, auditStatus, domain, state, navigate]);
    

    const fetchQuestions = useCallback(async () => {
        try {
            setLoading(true);
            const data = await getDecisionsQuestions(auditId);
            if (data.message) {
                setError(data.message);
                setLoading(false);
                return;
            }
            setResponses([]);
            setUrlId(data[0].url_id);
            setPageName(data[0].page_name);

            groupRef.current = data.flatMap(item => Object.keys(item.questions))[0];
            const allAttributes = data.flatMap(item => Object.values(item.questions).flat());
            setAttributes(allAttributes);
            setCurrentAttributeIndex(0);
            setCurrentQuestionIndex(0);
        setCurrentQuestionIndex(0);
        } catch (err) {
            console.error("Erreur lors de la récupération des données :", err);
            setError("Une erreur est survenue lors du chargement des données.");
        } finally {
            setLoading(false);
        }
    }, [auditId]);

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]);


    const handleResponse = (response: string) => {
        setSelectedOption(response); // Met à jour la réponse sélectionnée
    };

    const handleNext = async () => {
        if (!selectedOption) {
            alert("Veuillez sélectionner une option avant de continuer.");
            return;
        }
      
        const currentAttribute = attributes[currentAttributeIndex];
        const currentQuestion = currentAttribute.questions[currentQuestionIndex];
      
        const service = QuestionsServices[groupRef.current];
        if (!service) {
            setError("Service non disponible pour ce groupe.");
            return;
        }

        if (currentAttributeIndex + 1 >= attributes.length && responses.length > 0) {
            sendResponses(responses);
            return;
        }

        try {
            setLoading(true);
            // Appel du service pour obtenir la question suivante
            const result = await service.getNextQuestion(selectedOption, currentAttribute);

            setResponses((prevResponses) => {
                const updatedResponses = [...prevResponses, {
                    group: groupRef.current,
                    currentAttribute,
                    currentQuestion,
                    result,
                }];
            
                // Envoyer les réponses si c'est la fin
                if (currentAttributeIndex + 1 >= attributes.length) {
                    sendResponses(updatedResponses); // Passez la version à jour ici
                }
            
                return updatedResponses;
            });
            

            if (!result.hasOwnProperty('relatedQuestion')) {
                // Si la propriété relatedQuestion n'est pas présente, cela signifie qu'une seul question était necéssaire a répondre pour ce groupe de question
                setResponses(prev => [...prev, { group: groupRef.current, currentAttribute, currentQuestion, result }]);
                setCurrentAttributeIndex(prevIndex => prevIndex + 1);
                setCurrentQuestionIndex(0);
                
            } else if (result?.relatedQuestion) { // Si la propriété relatedQuestion est présente, cela signifie qu'il y a une question supplémentaire à répondre pour ce groupe de question
                setAttributes(prevAttributes => {
                    const updatedAttributes = [...prevAttributes];
                    const currentAttr = updatedAttributes[currentAttributeIndex];
            
                    // Remplacer ou ajouter la nouvelle question dans l'attribut actuel
                    if (!currentAttr.relatedQuestion) {
                        currentAttr.questions = [];
                    }
                    currentAttr.questions[currentQuestionIndex] = result.relatedQuestion;
                    
                    return updatedAttributes;
                });
                
                setResponses(prev => [...prev, { group: groupRef.current, currentAttribute, currentQuestion, result: {test_id: result.test_id, response: result.response} }]);
                // Réinitialiser l'option sélectionnée
                setSelectedOption(null);
            } else { // Sinon, ce else signifie que la question suivante est la dernière question du groupe
                setResponses(prev => [...prev, { group: groupRef.current, currentAttribute, currentQuestion, result: { test_id: currentAttribute.test_id, response: selectedOption } }]);
                setCurrentAttributeIndex(prevIndex => prevIndex + 1);
                setCurrentQuestionIndex(0);
            }
            
        } catch (err) {
            console.error("Erreur lors de la récupération de la question suivante :", err);
            setError("Une erreur est survenue lors du traitement de la réponse.");
        } finally {
            setLoading(false);
        }
    };
      
    const sendResponses = async (responses) => {
        try {
            await sendDecisionResponse(url_id, responses);
            await fetchQuestions(); // Rafraîchir les questions après envoi
        } catch (err) {
            console.error("Erreur lors de l'envoi des réponses :", err);
            setError("Une erreur est survenue lors de l'envoi des réponses.");
        }
    };
    
    
    if (loading) {
        return (
            <div className="container mt-8 d-flex flex-column align-items-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return <p className="text-danger">{error}</p>;
    }

    return (
        <div>
            <h1>Aide à la décision - {auditName}</h1>
            <div className="d-flex justify-content-between align-items-center">
                <span className="fs-4">{pageName}</span>
            </div>
            <div className="container mt-6 d-flex flex-column align-items-center">
                {currentAttribute && typeof currentAttribute.html === 'string' && (
                    currentAttribute.html.includes('<iframe') ? ( // Si le contenu contient une iframe, on affiche et le code et pas le rendu afin d'éviter d'aller chercher les ressources externes
                        <div className="toto p-8 bg-light" >
                            <span className="mw-400px">{currentAttribute.html}</span>
                        </div>
                    ) : (
                        <div className="toto p-8 bg-light" dangerouslySetInnerHTML={{ __html: currentAttribute.html }} style={{ maxWidth: "400px"}} />
                    )
                )}
                {currentQuestion && (
                    <div className="mt-10">
                        <h4>{currentQuestion.question}</h4>
                        <div className="d-flex flex-column align-items-center mt-5">
                            <div className="d-flex justify-content-center">
                                {currentQuestion.options.map((option: string, idx: number) => (
                                    <label
                                        key={idx}
                                        className="d-flex align-items-center justify-content-center border border-dark rounded p-4 h-40px cursor-pointer bg-hover-light-dark me-6"
                                    >
                                        <input
                                            type="radio"
                                            name={`response_${currentQuestion.test_id}`}
                                            value={option}
                                            onChange={() => handleResponse(option)} // Met à jour la réponse sélectionnée
                                            checked={selectedOption === option} // Coche l'option sélectionnée
                                        />
                                        <span className="ms-2">{option}</span>
                                    </label>
                                ))}
                            </div>
                            <button
                                className="btn btn-primary mt-4"
                                onClick={handleNext} // Soumettre la réponse et aller à la question suivante
                            >
                                Suivant
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AideDecisions;
