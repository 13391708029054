import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageTitle, usePageData } from '../../../../../../_metronic/layout/core/PageData';
import { getAllTestsByAudit, updateAuditStatus, updateCriteriaComment } from '../../../../../services/Api';
import { updateCriteriaResponse } from '../../../../../services/Api';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Tooltip } from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap';
import { Toast } from 'bootstrap';

interface Question {
    questionId: number;
    response: string;
    test: string;
    theme: string;
    criteria: {
        id: number;
        name: string;
        description: string;
        result: string;
        comments: string;
    }
}

interface UrlQuestions {
    url: string;
    pageName: string;
    tests: Question[];
    urlId: number;
}

interface ModeManuelLocationState {
    siteId: number;
    auditId: number;
    auditName: string;
    auditStatus: string;
    questions: UrlQuestions[];
    domain: string;
}

const ModeManuel: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [manualState] = useState<ModeManuelLocationState>(() => {
        const savedState = JSON.parse(localStorage.getItem('modeManuelState') || '{}');
        return location.state as ModeManuelLocationState || savedState;
    });
    const { auditId, auditName, questions, siteId, domain } = manualState || {};
    const { setPageBreadcrumbs } = usePageData();
    const [activeTab, setActiveTab] = useState<string>('');
    const [updatedQuestions, setUpdatedQuestions] = useState<UrlQuestions[]>([]);
    const themes = Array.from(new Set(updatedQuestions.flatMap(url => url.tests.map(test => test.theme))));
    const [themeProgress, setThemeProgress] = useState<{ [key: string]: number }>({});
    const [selectedTheme, setSelectedTheme] = useState<string>('');
    const [criteriaCounts, setCriteriaCounts] = useState<{ [key: string]: number }>({
        conforme: 0,
        nonConforme: 0,
        nonApplicable: 0,
        nonEvalue: 0,
    });
    const [auditStatus, setAuditStatus] = useState(manualState.auditStatus);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (manualState) {
            localStorage.setItem('modeManuelState', JSON.stringify(manualState));
        }
    }, [manualState]);

    const [filters, setFilters] = useState<{ [key: string]: boolean }>({
        conforme: false,
        nonConforme: false,
        nonApplicable: false,
        nonEvalue: false,
    });

    const [progressPercentage, setProgressPercentage] = useState<number>(0);

    const totalQuestions = updatedQuestions.reduce((count, urlQuestion) => {
        return count + urlQuestion.tests.length;
        
    }, 0);

    const answeredQuestions = updatedQuestions.reduce((count, urlQuestion) => {
        return count + urlQuestion.tests.filter(test => test.response && test.response !== "undefined").length;
    }, 0);

    const calculateProgress = useCallback(() => {
        return totalQuestions > 0 ? Math.round((answeredQuestions / totalQuestions) * 100) : 0;
    }, [totalQuestions, answeredQuestions]);

    const calculateThemeProgress = useCallback(() => {
        const themeCounts: { [key: string]: { total: number, evaluated: number } } = {};

        updatedQuestions.forEach(urlQuestion => {
            urlQuestion.tests.forEach(test => {
                const theme = test.theme;
                if (!themeCounts[theme]) {
                    themeCounts[theme] = { total: 0, evaluated: 0 };
                }
                themeCounts[theme].total += 1;
                if (test.response && test.response !== "undefined") {
                    themeCounts[theme].evaluated += 1;
                }
            });
        });

        const newThemeProgress: { [key: string]: number } = {};
        Object.keys(themeCounts).forEach(theme => {
            newThemeProgress[theme] = Math.round((themeCounts[theme].evaluated / themeCounts[theme].total) * 100);
        });

        setThemeProgress(newThemeProgress);
    }, [updatedQuestions]);

    const [comments, setComments] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        setPageBreadcrumbs([
            { title: 'Tableau de bord', path: '/tableau-de-bord', isActive: false },
            { title: 'Audits', path: `/sites/audits`, isActive: false, state: { siteId, domain, auditId, auditName, auditStatus } },
            { title: 'Synthèse', path: `/sites/audits/synthese`, isActive: false, state: { siteId, auditId, auditName, auditStatus, questions, domain } },
            { title: 'Mode Manuel', path: '', isActive: true },
        ]);

        if (auditId) {
            fetchQuestions(auditId);
        }


    }, [setPageBreadcrumbs, auditId, siteId, domain, auditName, auditStatus, questions]);

    useEffect(() => {
        const counts = {
            conforme: 0,
            nonConforme: 0,
            nonApplicable: 0,
            nonEvalue: 0,
        };
    
        // Utilisation d'un Set pour garantir que chaque critère est compté une seule fois
        const seenCriteria = new Set();
    
        updatedQuestions.forEach(urlQuestion => {
            urlQuestion.tests.forEach(test => {
                // Vérifier si le critère a déjà été compté
                if (!seenCriteria.has(`${test.criteria.id}-${urlQuestion.urlId}`)) {
                    // Ajouter le critère au Set
                    seenCriteria.add(`${test.criteria.id}-${urlQuestion.urlId}`);
                    // Calculer le résultat pour ce critère uniquement si il n'a pas déjà été compté
                    if (test.criteria.result === 'Conforme') counts.conforme++;
                    else if (test.criteria.result === 'Non conforme') counts.nonConforme++;
                    else if (test.criteria.result === 'Non applicable') counts.nonApplicable++;
                    else counts.nonEvalue++;
                }
            });
        });
    
        setCriteriaCounts(counts);
    }, [updatedQuestions]);
    

    const fetchQuestions = async (auditId: number) => {
        try {
            const response = await getAllTestsByAudit(auditId);
            setUpdatedQuestions(response);
            setComments(response.reduce((acc, urlData) => {
                urlData.tests.forEach(test => {
                    acc[`${test.criteria.id}-${urlData.urlId}`] = test.criteria.comments;
                });
                return acc;
            }, {})); // set les commentaires pour chaque critère
            if (response.length > 0) {
                setActiveTab(response[0].pageName);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Erreur lors de la récupération des questions de l'audit:", error);
        }
    };

    const handleTabClick = (pageName: string) => {
        setActiveTab(pageName);
    };
    
    const handleRadioClick = async (event, criteriaId, response, urlId, testId) => {
        try {
            // Vérifier si la réponse actuelle est sélectionnée
            let newResponse = response;
            
            updatedQuestions.forEach((urlData) => {
                if (urlData.urlId === urlId) {
                    urlData.tests.forEach((test) => {
                        if (test.criteria.id === criteriaId || test.questionId === testId) {
                            if (test.response === response) {
                                newResponse = "undefined"; // Désélectionner si la réponse actuelle est déjà sélectionnée
                            }
                        }
                    });
                }
            });
            
            // Mettre à jour l'état
            setUpdatedQuestions((prevQuestions) => {
                return prevQuestions.map((urlData) => {
                    if (urlData.urlId === urlId) {
                        return {
                            ...urlData,
                            tests: urlData.tests.map((test) => {
                                if (test.criteria.id === criteriaId || test.questionId === testId) {
                                    return { ...test, response: newResponse }; // Appliquer la nouvelle réponse
                                }
                                return test;
                            }),
                        };
                    }
                    return urlData;
                });
            });

            // Déterminer la valeur de la réponse pour le backend
            const responseValue =
                newResponse === 'Conforme' ? 1 :
                newResponse === 'Non conforme' ? -1 :
                newResponse === 'Non applicable' ? 0 : "undefined";
    
            const credentials = {
                criteriaId,
                testId,
                response: responseValue,
                urlId,
            };
            
            // Envoyer la requête au backend
            const result = await updateCriteriaResponse(credentials);
            if (auditStatus === "Terminée") {
                await updateAuditStatus(auditId, "En cours"); // Remet le status de l'audit à "En cours"
                setAuditStatus("En cours");
            }

            // Mettre à jour l'état local après une mise à jour réussie
            if (result?.status === 200) {
                setUpdatedQuestions((prevQuestions) =>
                    prevQuestions.map((urlData) =>
                        urlData.urlId === urlId
                            ? {
                                ...urlData,
                                tests: urlData.tests.map((test) => {
                                    if (test.criteria.id === criteriaId || test.questionId === testId) {
                                        return { ...test, criteria: { ...test.criteria, result: newResponse } };
                                    }
                                    return test;
                                }),
                              }
                            : urlData
                    )
                );
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la réponse :', error);
        }
    };

    const determineGlobalResponse = (tests: Question[]): string | null => {
        let hasNonConforme = false;
        let allConformeOrNonApplicable = true;
        let allNonApplicable = true;
    
        for (const test of tests) {
            if (test.response === 'Non conforme') {
                hasNonConforme = true;
            }
            if (test.response !== 'Conforme' && test.response !== 'Non applicable') {
                allConformeOrNonApplicable = false;
            }
            if (test.response !== 'Non applicable') {
                allNonApplicable = false;
            }
        }
    
        if (hasNonConforme) {
            return 'Non conforme';
        }
        if (allNonApplicable) {
            return 'Non applicable';
        }
        if (allConformeOrNonApplicable) {
            return 'Conforme';
        }
        return null;
    };
    
    const getResponseClass = (currentResponse: string, selectedResponse: string) => {
        switch (selectedResponse) {
            case 'Conforme':
                return currentResponse === 'Conforme' ? 'bg-light-success text-success radio-success' : '';
            case 'Non conforme':
                return currentResponse === 'Non conforme' ? 'bg-light-danger text-danger radio-danger' : '';
            case 'Non applicable':
                return currentResponse === 'Non applicable' ? 'bg-secondary text-black radio-secondary' : '';
            default:
                return '';
        }
    };

    const handleThemeClick = useCallback((theme: string) => {
        const section = document.getElementById(`theme-${theme}`);
        if (section) {
            section.scrollIntoView({ block: 'start' });
        }
        setSelectedTheme(theme);
        navigate(location.pathname, { state: { ...manualState, selectedTheme: theme } });
    }, [navigate, location.pathname, manualState]);

    const handleFilterChange = useCallback((filter: string) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filter]: !prevFilters[filter],
        }));
    }, []);
    
    const timeoutsRef = useRef<{ [key: string]: NodeJS.Timeout }>({});

    const handleCommentChange = async (criteriaId: number, comment: string, urlId: number) => {
        const key = `${criteriaId}-${urlId}`;
        
        // Mettre à jour le commentaire dans le state
        setComments((prevComments) => ({
            ...prevComments,
            [key]: comment,
        }));
        
        // Débouncer la mise à jour du commentaire
        if (timeoutsRef.current[key]) {
            clearTimeout(timeoutsRef.current[key]);
        }
    
        timeoutsRef.current[key] = setTimeout(async () => {
            try {
                // Appeler la fonction de mise à jour de l'API (par exemple `updateCriteriaComment`)
                const response = await updateCriteriaComment({ criteriaId, comment, urlId });

                if (response && response.data.comments) {
                    setComments((prevComments) => ({
                        ...prevComments,
                        [key]: response.data.comments,
                    }));
                }
            } catch (error) {
                console.error("Erreur lors de la mise à jour du commentaire :", error);
            } finally {
                // Nettoyer le timeout après exécution
                delete timeoutsRef.current[key];
            }
        }, 1000);
    };

    const getCriteriaPercentage = (status: string) => {
        const totalCriteriaCount = Object.values(criteriaCounts).reduce((acc, count) => acc + count, 0);
        if (totalCriteriaCount === 0) return 0;
        const statusCount = criteriaCounts[status] || 0;
        return Math.round((statusCount / totalCriteriaCount) * 100);
    };

    useEffect(() => {
    }, [updatedQuestions]);

    useEffect(() => {
        const calculatedProgress = calculateProgress();
        setProgressPercentage(calculatedProgress);
        calculateThemeProgress();
    }, [criteriaCounts, calculateProgress, calculateThemeProgress]);

    const [showModal, setShowModal] = useState(false);

    const [showToast, setShowToast] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const handleConfirm = async (status: string) => {
        await updateAuditStatus(auditId, status);
        setAuditStatus(status);
        handleClose();
        setShowToast(true); // Déclenche l'affichage du toast
    };

    
    useEffect(() => {
        if (showToast) {
            const toastEl = document.getElementById('deleteToast');
            if (toastEl) {
                const toast = new Toast(toastEl);
                toast.show();

                // Masquer le toast après 3 secondes
                const timer = setTimeout(() => {
                    toast.hide();
                    setShowToast(false); // Réinitialise l'état du toast
                }, 3000);

                return () => clearTimeout(timer); // Nettoyage du timer
            }
        }
    }, [showToast]);

    return (
        <div className="d-flex flex-column mt-4 min-h-1400px">
            <PageTitle breadcrumbs={[
                { title: 'Tableau de bord', path: '/tableau-de-bord', isActive: false },
                { title: 'Audits', path: `/sites/audits`, isActive: false, state: { siteId, domain, auditId, auditName, auditStatus} },
                { title: 'Synthèse', path: `/sites/audits/synthese`, isActive: false, state: { siteId, auditId, auditName, auditStatus, questions, domain } },
                { title: 'Mode Manuel', path: '', isActive: true },
            ]} />

            <div className='d-flex flex-column justify-content-center align-items-center p-4'>
                    <h1 className='display-6'>{auditName}</h1>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='fs-2 fw-lighter'>Vérification de l'audit</span>
                    </div>
            </div>

            <div className=''>

                {/* Barre de progression */}
                <div className="mb-4 d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column flex-grow-1 me-4">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <span>Progression de l'audit</span>
                            <span>{progressPercentage}%</span>
                        </div>
                        <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow={progressPercentage} aria-valuemin={0} aria-valuemax={100} style={{ height: '5px' }}>
                            <div className="progress-bar" style={{ width: `${progressPercentage}%`, transition: 'width 0.4s ease-in-out' }}></div>
                        </div>
                    </div>
                    {progressPercentage === 100 && auditStatus === "En cours" && (
                        <div className="d-flex justify-content-end" style={{ width: '150px', height: '40px' }}>
                            <button className="btn btn-info h-100 d-flex align-items-center justify-content-center" onClick={handleShow}>
                                Valider l'audit
                            </button>
                        </div>
                    )}
                </div>

                <div id="kt_docs_toast_stack_container" className="toast-container position-fixed top-0 end-0 p-3">
                    <div id="deleteToast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="toast-header bg-success text-white">
                            <strong className="me-auto">Succès</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                        <div className="toast-body bg-success text-white">
                            L'audit a été validé avec succès.
                        </div>
                    </div>
                </div>

                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Êtes-vous sûr de vouloir valider l'audit ?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button variant="primary" onClick={() => handleConfirm("Terminée")}>
                            Confirmer
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Conteneur pour les pourcentages des critères */}
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center mt-20">
                        <div className="spinner-border" role="status" style={{ width: '3rem', height: '3rem' }}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className='d-flex justify-content-around mt-8 mb-8 w-100 rounded shadow-sm'>
                        <div className='d-flex align-items-center w-100 justify-content-between'>
                            {auditStatus === "Terminée" && (
                                <div className='flex-grow-1 d-flex flex-column align-items-center p-5 mw-400px'>
                                    <h4 className='mb-10'>Taux global de conformité</h4>
                                    <div className='d-flex flex-column align-items-center mb-6'  style={{width: '120px',height: '120px' }}>
                                        
                                        <CircularProgressbar
                                            value={criteriaCounts.conforme / (criteriaCounts.conforme + criteriaCounts.nonConforme) * 100}
                                            text={`${Math.round(criteriaCounts.conforme / (criteriaCounts.conforme + criteriaCounts.nonConforme) * 100)}%`}
                                            styles={{
                                                path: {
                                                    stroke: (() => {
                                                        const percentage = criteriaCounts.conforme / (criteriaCounts.conforme + criteriaCounts.nonConforme) * 100;
                                                        if (percentage === 100) return '#50cd89'; // Si 100% alors Vert
                                                        if (percentage > 50) return '#ffe800'; // Si entre 50 et 99 alors Jaune
                                                        return '#f1416c'; // Sinon Rouge
                                                    })(),
                                                },
                                                text: {
                                                    fill: 'black',
                                                    fontSize: '16px',
                                                    textAnchor: 'middle', // Centrer horizontalement
                                                    dominantBaseline: 'middle', // Centrer verticalement
                                                },
                                                trail: {
                                                    stroke: '#f8f8f8',
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className='d-flex align-items-center'
                                        data-tooltip-id="globalConformity"
                                        data-tooltip-place="top"
                                        data-tooltip-variant={(() => {
                                            const percentage = criteriaCounts.conforme / (criteriaCounts.conforme + criteriaCounts.nonConforme) * 100;
                                            if (percentage === 100) return 'success';
                                            if (percentage > 50) return 'warning';
                                            return 'error';
                                        })()}
                                        data-tooltip-content={(() => {
                                            const percentage = criteriaCounts.conforme / (criteriaCounts.conforme + criteriaCounts.nonConforme) * 100;
                                            if (percentage === 100) return 'Tous les critères de contrôle du RGAA sont respectés ';
                                            if (percentage > 50) return 'Au moins 50 % des critères de contrôle du RGAA sont respectés';
                                            return 'Moins de 50 % des critères de contrôle du RGAA sont respectés';
                                        })()}>   
                                        <span
                                            className='fs-4'
                                        >
                                            {(() => {
                                                const percentage = criteriaCounts.conforme / (criteriaCounts.conforme + criteriaCounts.nonConforme) * 100;
                                                if (percentage === 100) return 'Totalement conforme';
                                                if (percentage > 50) return 'Partiellement conforme';
                                                return 'Totalement non conforme';
                                            })()}
                                        </span>
                                        <i className="ki-duotone ki-information-2 fs-3 ms-2 text-info">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                            <span className="path3"></span>
                                        </i>
                                        <Tooltip id="globalConformity" />
                                    </div>
                                </div>
                            )}

                            <div className='p-5 text-center flex-grow-1 d-flex flex-column align-items-center'>
                                <h4 className='mb-10'>Évaluation des critères</h4>
                                {/* Circular progress bar pour chaque critère */}
                                <div className="w-100 d-flex justify-content-around align-items-center">

                                    <div className="d-flex flex-column align-items-center me-2">
                                        <div className="d-flex flex-column align-items-center mb-6" 
                                            data-tooltip-id="conforme"
                                            data-tooltip-place="left"
                                            data-tooltip-variant="success"
                                            data-tooltip-content={`${getCriteriaPercentage('conforme')}%`}
                                            style={{width: '120px',height: '120px' }}>
                                            
                                            <CircularProgressbar
                                                value={criteriaCounts.conforme}
                                                text={`${criteriaCounts.conforme}`}
                                                styles={{
                                                    path: {
                                                        stroke: '#50cd89',
                                                    },
                                                    text: {
                                                        fill: 'black',
                                                        fontSize: '12px',
                                                        textAnchor: 'middle', // Centrer horizontalement
                                                        dominantBaseline: 'middle', // Centrer verticalement
                                                    },
                                                    trail: {
                                                        stroke: '#f8f8f8',
                                                    },
                                                }}
                                            />
                                            <Tooltip id='conforme'/>
                                            
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='bg-success rounded' style={{ width: '20px', height: '20px', marginRight: '8px' }}></div>
                                            <span className='text-black'>Conforme</span>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex flex-column align-items-center me-2">
                                        <div className="d-flex flex-column align-items-center mb-6" 
                                            data-tooltip-id="nonConforme"
                                            data-tooltip-place="left"
                                            data-tooltip-variant="error"
                                            data-tooltip-content={`${getCriteriaPercentage('nonConforme')}%`}
                                            style={{width: '120px',height: '120px' }}>

                                            <CircularProgressbar
                                                value={criteriaCounts.nonConforme}
                                                text={`${criteriaCounts.nonConforme}`}
                                                styles={{
                                                    path: {
                                                        stroke: '#f1416c',
                                                    },
                                                    text: {
                                                        fill: 'black',
                                                        fontSize: '12px',
                                                        textAnchor: 'middle', // Centrer horizontalement
                                                        dominantBaseline: 'middle', // Centrer verticalement
                                                    },
                                                    trail: {
                                                        stroke: '#f8f8f8',
                                                    },
                                                }}
                                            />
                                            <Tooltip id="nonConforme"/>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='bg-danger rounded' style={{ width: '20px', height: '20px', marginRight: '8px' }}></div>
                                            <span className='text-black'>Non conforme</span>
                                        </div>
                                    </div>
                                
                                    <div className="d-flex flex-column align-items-center me-2">
                                        <div className="d-flex flex-column align-items-center mb-6" 
                                            data-tooltip-id="nonApplicable"
                                            data-tooltip-place="left"
                                            data-tooltip-variant="light"
                                            data-tooltip-content={`${getCriteriaPercentage('nonApplicable')}%`}
                                            style={{width: '120px',height: '120px' }}>

                                            <CircularProgressbar
                                                value={criteriaCounts.nonApplicable}
                                                text={`${criteriaCounts.nonApplicable}`}
                                                styles={{
                                                    path: {
                                                        stroke: '#dbdfe9',
                                                    },
                                                    text: {
                                                        fill: 'black',
                                                        fontSize: '12px',
                                                        textAnchor: 'middle', // Centrer horizontalement
                                                        dominantBaseline: 'middle', // Centrer verticalement
                                                    },
                                                    trail: {
                                                        stroke: '#f8f8f8',
                                                    },
                                                }}
                                            />
                                            <Tooltip id='nonApplicable'/>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='bg-secondary rounded' style={{ width: '20px', height: '20px', marginRight: '8px' }}></div>
                                            <span className='text-black'>Non applicable</span>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column align-items-center me-2">
                                        <div className="d-flex flex-column align-items-center mb-6" 
                                            data-tooltip-id="nonEvalue"
                                            data-tooltip-place="left"
                                            data-tooltip-variant="light"
                                            data-tooltip-content={`${getCriteriaPercentage('nonEvalue')}%`}
                                            style={{width: '120px',height: '120px' }}>

                                            <CircularProgressbar
                                                value={criteriaCounts.nonEvalue}
                                                text={`${criteriaCounts.nonEvalue}`}
                                                styles={{
                                                    path: {
                                                        stroke: 'lightgray',
                                                    },
                                                    text: {
                                                        fill: 'black',
                                                        fontSize: '12px',
                                                        textAnchor: 'middle', // Centrer horizontalement
                                                        dominantBaseline: 'middle', // Centrer verticalement
                                                    },
                                                    trail: {
                                                        stroke: '#f8f8f8',
                                                    },
                                                }}
                                            />
                                            <Tooltip id='nonEvalue'/>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='bg-light rounded' style={{ width: '20px', height: '20px', marginRight: '8px' }}></div>
                                            <span>Non évalué</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            
                <div className='d-flex'>

                    {/* Menu latéral */}
                    <div className="menu menu-fit menu-column menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100 position-sticky h-100px flex-shrink-1 mw-250px" style={{ top: '10px' }}>
                        {/* Titre */}
                        <div className='d-flex align-items-center'><h1 className='mb-0'>Filtres</h1></div>
                    
                        <div className='mt-6 mb-4'><h5>Thématiques de critères</h5></div>

                        {/* Thèmes de critères */}
                        <div className='mb-4'>
                            {themes.map((theme, idx) => (
                                <div key={idx} className="menu-item rounded-lg mb-2">
                                    <a
                                        href={`#${theme}`}
                                        onClick={() => handleThemeClick(theme)}
                                        className={`menu-link ${selectedTheme === theme ? 'bg-light' : ''}`}
                                    >
                                        <span className='me-4'>{idx + 1}.</span>
                                        <span className={`menu-title ${selectedTheme === theme ? 'text-primary' : ''}`}>{theme}</span>
                                    </a>
                                    <div className="progress" role="progressbar" aria-label={`Progression du thème ${theme}`} aria-valuenow={themeProgress[theme]} aria-valuemin={0} aria-valuemax={100} style={{ height: '2px' }}>
                                        <div className="progress-bar" style={{ width: `${themeProgress[theme]}%`, transition: 'width 0.4s ease-in-out', }}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                        <div className='mt-8'><h5>Critères</h5></div>
                        {/* Filtres de critères */}
                        <div className='mt-3'>
                            <div className="form-check mb-4 cursor-pointer">
                                <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    id="flexCheckDefault"
                                    checked={filters.conforme}
                                    onChange={() => handleFilterChange('conforme')}
                                />
                                <label className="form-check-label cursor-pointer ms-1 text-dark" htmlFor="flexCheckDefault">
                                    Conforme ({criteriaCounts.conforme})
                                </label>
                            </div>
                            <div className="form-check mb-4 cursor-pointer">
                                <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    id="nonConforme"
                                    checked={filters.nonConforme}
                                    onChange={() => handleFilterChange('nonConforme')}
                                />
                                <label className="form-check-label cursor-pointer ms-1 text-dark" htmlFor="nonConforme">
                                    Non conforme ({criteriaCounts.nonConforme})
                                </label>
                            </div>
                            <div className="form-check mb-4 cursor-pointer">
                                <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    id="nonApplicable"
                                    checked={filters.nonApplicable}
                                    onChange={() => handleFilterChange('nonApplicable')}
                                />
                                <label className="form-check-label cursor-pointer ms-1 text-dark" htmlFor="nonApplicable">
                                    Non applicable ({criteriaCounts.nonApplicable})
                                </label>
                            </div>
                            <div className="form-check mb-4 cursor-pointer">
                                <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    id="nonEvalue"
                                    checked={filters.nonEvalue}
                                    onChange={() => handleFilterChange('nonEvalue')}
                                />
                                <label className="form-check-label cursor-pointer ms-1 text-dark" htmlFor="nonEvalue">
                                    Non évalué ({criteriaCounts.nonEvalue || 0})
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Contenu principal */}
                    <div className="content flex-grow-1" style={{ paddingLeft: 0, minHeight: 1000 }}>
                        {/* Onglets déroulants */}
                        <div className="sticky-top bg-white">
                            <div className="d-grid">
                                <ul className="nav nav-tabs flex-nowrap text-nowrap w-100 h-40px">
                                    {updatedQuestions?.map((urlData) => (
                                        <li className="nav-item" key={urlData.pageName}>
                                            <button
                                                className={`nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0 p ${activeTab === urlData.pageName ? 'active ' : ''}`}
                                                onClick={() => handleTabClick(urlData.pageName)}
                                            >
                                                {urlData.pageName}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/* Liens de page */}
                        <div className='d-flex justify-content-end mt-3'>
                            {updatedQuestions
                                .filter((urlData) => urlData.pageName === activeTab)
                                .map((urlData) => (
                                    <a key={urlData.url} href={urlData.url} className='me-2 d-flex align-items-center link-opacity-50-hover link-offset-2'>
                                        <span className='text-decoration-underline'>{urlData.url}</span>
                                        <i className="ki-duotone ki-exit-right-corner cursor-pointer ms-2">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                    </a>
                                ))}
                        </div>

                        {updatedQuestions
                            .filter((urlData) => urlData.pageName === activeTab) // Filtre par onglet actif
                            .map((urlData) => {
                                // Filtrer les critères par filtres actifs
                                const filteredCriteria = Object.entries(
                                    urlData.tests.reduce((acc, test) => {
                                        if (!acc[test.criteria.id]) {
                                            acc[test.criteria.id] = [];
                                        }
                                        acc[test.criteria.id].push(test);
                                        return acc;
                                    }, {} as Record<number, Question[]>)
                                ).filter(([, tests]) => {
                                    const { conforme, nonConforme, nonApplicable, nonEvalue } = filters;

                                    // Si aucun filtre n'est actif, inclure tous les tests
                                    const isAnyFilterActive = conforme || nonConforme || nonApplicable || nonEvalue;
                                    if (!isAnyFilterActive) return true;

                                    // Appliquer les filtres actifs
                                    return tests.some((test) => {
                                        const result = test.criteria.result;
                                        return (
                                            (conforme && result === 'Conforme') ||
                                            (nonConforme && result === 'Non conforme') ||
                                            (nonApplicable && result === 'Non applicable') ||
                                            (nonEvalue && result === 'undefined')
                                        );
                                    });
                                });

                                // Vérifier si aucun critère ne correspond aux filtres
                                if (filteredCriteria.length === 0) {
                                    return (
                                        <div key={urlData.pageName} className='ps-12'>
                                            <div className="d-flex flex-column p-8 align-items-center">
                                                <h3 className="mb-5">Aucun critère ne correspond à la recherche.</h3>
                                                <span>Veuillez sélectionner un filtre contenant au moins un critère.</span>
                                            </div>
                                        </div>
                                    );
                                }

                                // Grouper les critères filtrés par thème
                                const criteriaByTheme = themes.reduce((acc, theme) => {
                                    acc[theme] = filteredCriteria.filter(([, tests]) => tests.some(test => test.theme === theme));
                                    return acc;
                                }, {} as Record<string, [string, Question[]][]>);

                                return (
                                    <div key={urlData.pageName} className='ps-12'>
                                        {themes.map((theme) => {
                                            const themeCriteria = criteriaByTheme[theme];
                                            if (themeCriteria.length === 0) return null;

                                            return (
                                                <section key={theme} id={`theme-${theme}`} className="mb-5 pt-18">
                                                    <h2>{theme}</h2>
                                                    {/* Affiche les critères filtrés */}
                                                    <ol className='list-unstyled'>
                                                        {themeCriteria.map(([criteriaId, tests]) => {
                                                            // Déterminer la réponse globale pour le critère
                                                            const globalResponse = determineGlobalResponse(tests) || 'undefined';
                                                            
                                                            return (
                                                                <li key={`${urlData.pageName}-${criteriaId}`} className='p-4'>
                                                                    <div className='card bordered shadow-sm'>
                                                                        <div className='card-header collapsible cursor-pointer rotate d-flex flex-nowrap bg-hover-light mb-4' data-bs-toggle="collapse" data-bs-target={`#kt_docs_card_collapsible_${urlData.pageName}-${criteriaId}`}>
                                                                            <h3 className='card-title'>{tests[0].criteria.name} - {tests[0].criteria.description}</h3>
                                                                            <div className="ms-auto d-flex align-items-center">
                                                                                <span className="me-2 fs-8">{tests.filter(test => test.response && test.response !== "undefined").length}/{tests.length}</span>
                                                                                <div className="card-toolbar rotate-180">
                                                                                    <i className="ki-duotone ki-down fs-1"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {/* Options pour le critère entier */}
                                                                        <div className="d-flex justify-content-around mb-8">

                                                                            <label
                                                                                className={`border rounded-pill p-3 d-flex align-items-center border-gray-600 bg-hover-light-success ${getResponseClass(globalResponse, 'Conforme')}`}
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`criteria-response-${urlData.urlId}-${criteriaId}`}
                                                                                    value="Conforme"
                                                                                    checked={globalResponse === 'Conforme'} readOnly
                                                                                    onClick={(event) => handleRadioClick(event, Number(criteriaId), "Conforme", urlData.urlId, null)} />
                                                                                <span className="ms-2">Conforme</span>
                                                                            </label>

                                                                            <label
                                                                                className={`border rounded-pill p-3 d-flex align-items-center border-gray-600 bg-hover-light-danger ${getResponseClass(globalResponse, 'Non conforme')}`}
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`criteria-response-${urlData.urlId}-${criteriaId}`}
                                                                                    value="Non conforme"
                                                                                    checked={globalResponse === 'Non conforme'} readOnly
                                                                                    onClick={(event) => handleRadioClick(event, Number(criteriaId), "Non conforme", urlData.urlId, null)} />
                                                                                <span className="ms-2">Non conforme</span>
                                                                            </label>

                                                                            <label
                                                                                className={`border rounded-pill p-3 d-flex align-items-center border-gray-600 bg-hover-light-secondary ${getResponseClass(globalResponse, 'Non applicable')}`}
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`criteria-response-${urlData.urlId}-${criteriaId}`}
                                                                                    value="Non applicable"
                                                                                    checked={globalResponse === 'Non applicable'} readOnly
                                                                                    onClick={(event) => handleRadioClick(event, Number(criteriaId), "Non applicable", urlData.urlId, null)} />
                                                                                <span className="ms-2">Non applicable</span>
                                                                            </label>
                                                                        </div>

                                                                        {/* Commentaire pour le critère entier */}
                                                                        <div className={`rounded border d-flex flex-column p-10 ${['Conforme', 'Non conforme', 'Non applicable'].includes(globalResponse) ? '' : 'd-none'}`}>
                                                                            <label className="form-label">
                                                                                <span>
                                                                                    {globalResponse === 'Conforme' ? 'Points d\'amélioration' : ''}
                                                                                    {globalResponse === 'Non conforme' ? 'Erreur et recommandation' : ''}
                                                                                    {globalResponse === 'Non applicable' ? 'Commentaire' : ''}
                                                                                </span>
                                                                            </label>
                                                                            <textarea className="form-control form-control form-control-solid min-h-100px" 
                                                                                data-kt-autosize="true"
                                                                                value={comments[`${criteriaId}-${urlData.urlId}`] || ''}
                                                                                onChange={(event) => handleCommentChange(Number(criteriaId), event.target.value, urlData.urlId)}>
                                                                            </textarea>
                                                                        </div>

                                                                        {/* Tests individuels */}
                                                                        <div id={`kt_docs_card_collapsible_${urlData.pageName}-${criteriaId}`} className="collapse">
                                                                            <div className='card-body'>
                                                                                {/* Tests individuels */}
                                                                                {tests.map((data) => {
                                                                                    // Crée un identifiant unique pour chaque question
                                                                                    const questionIndex = `${urlData.pageName}-${criteriaId}-${data.questionId}`;

                                                                                    return (
                                                                                        <div key={questionIndex} className="mb-3">
                                                                                            <div className="border border-5 border-radius-lg p-5">
                                                                                                <p>{data.test}</p>
                                                                                                <div className="separator my-5"></div>

                                                                                                {/* Options de réponse modifiables */}
                                                                                                <div className="d-flex justify-content-around mt-3">
                                                                                                    <label
                                                                                                        className={`border rounded-pill p-3 d-flex align-items-center border-gray-600 bg-hover-light-success ${getResponseClass(data.response, 'Conforme')}`}
                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                    >
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            name={`response-${questionIndex}`}
                                                                                                            value="Conforme"
                                                                                                            checked={data.response === 'Conforme'} readOnly
                                                                                                            onClick={(event) => handleRadioClick(event, null, 'Conforme', urlData.urlId, data.questionId)} />
                                                                                                        <span className="ms-2">Conforme</span>
                                                                                                    </label>

                                                                                                    <label
                                                                                                        className={`border rounded-pill p-3 d-flex align-items-center border-gray-600 bg-hover-light-danger ${getResponseClass(data.response, 'Non conforme')}`}
                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                    >
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            name={`response-${questionIndex}`}
                                                                                                            value="Non conforme"
                                                                                                            checked={data.response === 'Non conforme'} readOnly
                                                                                                            onClick={(event) => handleRadioClick(event, null, 'Non conforme', urlData.urlId, data.questionId)} />
                                                                                                        <span className="ms-2">Non conforme</span>
                                                                                                    </label>

                                                                                                    <label
                                                                                                        className={`border rounded-pill p-3 d-flex align-items-center border-gray-600 bg-hover-light-secondary ${getResponseClass(data.response, 'Non applicable')}`}
                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                    >
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            name={`response-${questionIndex}`}
                                                                                                            value="Non applicable"
                                                                                                            checked={data.response === 'Non applicable'} readOnly
                                                                                                            onClick={(event) => handleRadioClick(event, null, 'Non applicable', urlData.urlId, data.questionId)} />
                                                                                                        <span className="ms-2">Non applicable</span>
                                                                                                    </label>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                    </ol>
                                                </section>
                                            );
                                        })}
                                    </div>
                                );
                            })
                        }   
                    </div>

                </div>
            </div>
            
        </div>
    );
};

export default ModeManuel;
