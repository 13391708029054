import React, { FC } from 'react';
import Sites from '../sites/Sites';

interface DashboardWrapperProps {
  orgaId: number;
}

const DashboardWrapper: FC<DashboardWrapperProps> = ({ orgaId }) => {
  return (
    <div className="container">
      <Sites orgaId={orgaId} />
    </div>
  );
};

export default DashboardWrapper;