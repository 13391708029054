import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSite } from '../../../services/Api';
import { PageTitle } from '../../../../_metronic/layout/core';

interface CreateSitePageProps {
    orgaId: number;
}

const CreateSitePage: FC<CreateSitePageProps> = ({ orgaId }) => {
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            await createSite({ name, url, orgaId });
            setSuccess('Site créé avec succès');
            setName('');
            setUrl('');
            setTimeout(() => {
                navigate('/tableau-de-bord');
        }, 1000); // Rediriger après 2 secondes
        } catch (error) {
            setError('Erreur lors de la création du site');
        } finally {
            setLoading(false);
        }
        };

        return (
            <div className="container mt-4">
                <PageTitle breadcrumbs={[
                    { title: 'Tableau de bord', path: '/tableau-de-bord', isActive: false },
                    { title: 'Nouveau', path: '/nouveau', isActive: true },
                ]} />
                <h2>Ajouter un nouveau site</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="form-label">Nom du site</label>
                            <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="url" className="form-label">Domaine du site</label>
                        <input
                        type="url"
                        className="form-control"
                        id="url"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        required
                        />
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {success && <div className="alert alert-success">{success}</div>}
                    <button type="submit" className="btn btn-primary hover-elevate-up" disabled={loading}>
                        {loading ? 'Chargement...' : 'Ajouter le site'}
                    </button>
                </form>
            </div>
        );
};

export default CreateSitePage;