import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import {WithChildren} from '../../_metronic/helpers'
import Audits from '../pages/sites/audits/Audits'
import CreateSitePage from '../pages/sites/nouveau/CreateSitePage'
import SitesList from '../pages/sites/Sites'
import ModeManuel from '../pages/sites/audits/synthese/mode-manuel/ModeManuel'
import AideDecisions from '../pages/sites/audits/synthese/aide-decisions/AideDecisions'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CreateAuditPage = lazy(() => import('../pages/sites/audits/nouveau/CreateAuditPage'))
  const Synthese = lazy(() => import('../pages/sites/audits/synthese/Synthese'))
  const TestAutomatiques = lazy(() => import('../pages/sites/audits/synthese/tests-automatiques/TestsAutomatiques'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/tableau-de-bord' />} />
        {/* Pages */}
        <Route path='/tableau-de-bord' element={<DashboardWrapper orgaId={1} />} />
        <Route path='/sites' element={<SitesList orgaId={1} />} />
        <Route path='/sites/nouveau' element={<CreateSitePage orgaId={1} />} />
        <Route path="/sites/audits" element={<Audits />} />
        <Route path='/sites/audits/nouveau' element={<CreateAuditPage/>} />
        <Route path='/sites/audits/synthese' element={<Synthese />} />
        
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='audits/nouveau'
          element={
            <SuspensedView>
              <CreateAuditPage/>
            </SuspensedView>
          }
        />
        <Route
          path='audits/synthese/tests-automatiques'
          element={
            <SuspensedView>
              <TestAutomatiques />
            </SuspensedView>
          }
        />  
        <Route
          path='audits/synthese/mode-manuel'
          element={
            <SuspensedView>
              <ModeManuel />
            </SuspensedView>
          }
        />
        <Route
          path='audits/synthese/aide-decisions'
          element={
            <SuspensedView>
              <AideDecisions />
            </SuspensedView>
          }
        />
        <Route
          path='audits/synthese'
          element={
            <SuspensedView>
              <Synthese />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
