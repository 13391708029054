import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; 

// Fonction pour récupérer les audits d'un utilisateur
export const getAuditsBySite= async (siteId: number) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-audit/${siteId}`);
        if (!response.data.audits) {
            return;
        }
        return response.data.audits; // Retourne la liste des audits
    } catch (error) {
        console.error('Erreur lors de la récupération des audits :', error);
        throw error; // Rejette l'erreur pour gestion ultérieure
    }
};

// Fonction pour récupérer les sites d'une organisation
export const getSitesByOrga = async (orgaId: number) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-sites/${orgaId}`);
        if (!response.data) {
            return;
        }
        return response.data; // Retourne la liste des sites
    } catch (error) {
        console.error('Erreur lors de la récupération des sites :', error);
        throw error; 
    }
};

export const deleteSiteById = async (siteId: number) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/delete-site/${siteId}`);
        return response;
    } catch (error) {
        console.error('Erreur lors de la suppression du site:', error);
        throw error;
    }
}

export const launchAudit = async (auditId: number) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/launch-audit/${auditId}`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors du lancement de l\'audit:', error);
        throw error;
    }
}

export const createSite = async (siteData: { name: string, url: string, orgaId: number }) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/create-site`, siteData);
        return response;
    } catch (error) {
        console.error('Erreur lors de la création du site:', error);
        throw error;
    }
};

export const createAudit = async (auditData: { name: string, pages: { pageName: string, url: string }[], siteId: number }) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/create-audit`, auditData);
        return response;
    } catch (error) {
        console.error('Erreur lors de la création de l\'audit:', error);
        throw error;
    }
};

export const deleteAudit = async (auditId: number) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/delete-audit/${auditId}`);
        return response;
    } catch (error) {
        console.error('Erreur lors de la suppression de l\'audit:', error);
        throw error;
    }
};

export const checkAuditStatus = async (auditId: number) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/check-audit-status/${auditId}`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la vérification du statut de l\'audit:', error);
        throw error;
    }
}

export const getTestsAutoByAudit = async (auditId: number) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-tests-auto-by-audit/${auditId}`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des questions de l\'audit:', error);
        throw error;
    }
};

export const getAllTestsByAudit = async (auditId: number) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-all-tests-by-audit/${auditId}`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des questions de l\'audit:', error);
        throw error;
    }
}
export const updateCriteriaResponse = async (credentials: { criteriaId: number, testId: number, response: number | string, urlId: number}) => {
    try {
        const result = await axios.put(`${API_BASE_URL}/update-criteria-response`, { credentials });
        return result;
    } catch (error) {
        console.error('Erreur lors de la mise à jour de la réponse:', error);
        throw error;
    }
}

export const updateCriteriaComment = async (credentials: { criteriaId: number, comment: string, urlId: number}) => {
    try {
        const result = await axios.put(`${API_BASE_URL}/update-criteria-comment`, { credentials });
        return result;
    } catch (error) {
        console.error('Erreur lors de la mise à jour du commentaire:', error);
        throw error;
    }
}

export const updateAuditStatus = async (auditId: number, status: string) => {
    try {
        const result = await axios.put(`${API_BASE_URL}/update-audit-status/${auditId}`, { status });
        return result;
    } catch (error) {
        console.error('Erreur lors de la mise à jour du statut de l\'audit:', error);
        throw error;
    }
}

export const getLastRef = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-last-ref`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération du dernier référentiel:', error);
        throw error;
    }
}

export const getDecisionsQuestions = async (auditId: number) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-decision-questions/${auditId}`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des questions de décision:', error);
        throw error;
    }
}

export const sendDecisionResponse = async (urlId: number, responses: {}) => {
    try {
        const result = await axios.post(`${API_BASE_URL}/send-decision-response/${urlId}`, { responses });
        return result;
    } catch (error) {
        console.error('Erreur lors de l\'envoi des réponses de décision:', error);
        throw error;
    }
}

export const isValidUrl = (url: string, domain: string): boolean => {
    // Extraire le domaine de l'URL utilisateur
    const userDomain = extractDomain(url);
    const referenceDomain = extractDomain(domain);
    // Vérifier le format de l'URL et si le domaine correspond
    const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/.*)?$/;
    if (!urlRegex.test(url) || userDomain !== referenceDomain) {
        console.error('URL non valide ou domaine différent');
        return false;
    }

    return true; // Renvoie true si l'URL est valide et le domaine correspond
};

// Fonction pour extraire le domaine d'une URL
const extractDomain = (url: string): string => {
    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch (error) {
        console.error('Erreur lors de l\'extraction du domaine :', error);
        return '';
    }
};
