import React, { FC, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuditsBySite, deleteAudit as deleteAuditService } from '../../../services/Api';
import { PageTitle } from '../../../../_metronic/layout/core';
import { Modal, Button } from 'react-bootstrap';
import { Toast } from 'bootstrap';

interface Audit {
  id: number;
  name: string;
  urls: { page_name: string; url: string }[];
  accessibilityRate: number;
  created_at: string;
  status: string;
  referential: string;
  stauts: string;
}

interface AuditLocationState {
  siteId: number;
  domain: string;
}

const Audits: FC = () => {
  const location = useLocation();
  const state = location.state as AuditLocationState;
  const siteId = state?.siteId;
  const domain = state?.domain;;
  const [audits, setAudits] = useState<Audit[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(false); // État pour afficher le toast
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedAuditId, setSelectedAuditId] = useState<number | null>(null);

  const fetchAudits = useCallback(async () => {
    try {
      const response = await getAuditsBySite(Number(siteId));
      setAudits(response);
    } catch (error) {
      setError('Erreur lors de la récupération des audits.');
    } finally {
      setLoading(false);
    }
  }, [siteId]);

  useEffect(() => {
    fetchAudits();
  }, [fetchAudits]);

  const handleContinueAudit = async (auditId: number, auditName: string, auditStatus: string) => {
    try {
      navigate(`/audits/synthese`, { state: { siteId, auditId, auditName, domain, auditStatus } });
    } catch (error) {
      console.error("Erreur lors de la récupération des questions de l'audit:", error);
    }
  };

  const handleDeleteAudit = async (auditId: number) => {
    try {
      await deleteAuditService(auditId);
      setAudits((prevAudits) => prevAudits.filter((audit) => audit.id !== auditId));
      setShowToast(true); // Déclenche l'affichage du toast
    } catch (error) {
      console.error("Erreur lors de la suppression de l'audit:", error);
    }
  };

  const handleShowModal = (auditId: number) => {
    setSelectedAuditId(auditId);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    if (selectedAuditId !== null) {
      handleDeleteAudit(selectedAuditId);
    }
    setShowModal(false);
  };


  useEffect(() => {
    if (showToast) {
      const toastEl = document.getElementById('deleteToast');
      if (toastEl) {
        const toast = new Toast(toastEl);
        toast.show();

        // Masquer le toast après 3 secondes
        const timer = setTimeout(() => {
          toast.hide();
          setShowToast(false); // Réinitialise l'état du toast
        }, 3000);

        return () => clearTimeout(timer); // Nettoyage du timer
      }
    }
  }, [showToast]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-primary hover-elevate-up"
          onClick={() => navigate(`/audits/nouveau`, { state: { siteId, domain } })}
        >
          Créer un audit
        </button>
      </div>
      <div>
        <PageTitle
          breadcrumbs={[
            { title: 'Tableau de bord', path: '/tableau-de-bord', isActive: false },
            { title: 'Audits', path: `/sites/audits`, isActive: true },
          ]}
        />
        <h3>Mes audits</h3>
        {audits && audits.length > 0 ? (
        <div className="table-responsive p-6">
          <table
            id="kt_datatable_zero_configuration"
            className="table table-row-bordered gy-5 text-center align-middle"
          >
            <thead>
              <tr className="fw-semibold fs-6 text-muted">
                <th scope="col">Nom</th>
                <th scope="col">Statut</th>
                <th scope="col">Date de création</th>
                <th scope="col">Référentiel</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {audits.map((audit) => (
                <tr key={audit.id}>
                  <td>{audit.name}</td>
                  <td>
                      <span
                      className={`badge ${
                        audit.status === "En cours"
                        ? "bg-warning"
                        : audit.status === "Init"
                        ? "bg-secondary"
                        : "bg-success"
                      }`}
                      >
                      {audit.status === "Init" ? "Initié" : audit.status}
                      </span>
                  </td>
                  <td>{new Date(audit.created_at).toLocaleDateString("fr-FR")}</td>
                  <td>{audit.referential}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-active-secondary btn-active-color-success fw-bolder fs-8 fs-lg-base me-2 hover-elevate-up"
                      onClick={() => handleContinueAudit(audit.id, audit.name, audit.status)}
                    >
                      Continuer
                      <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-active-secondary btn-active-color-danger fw-bolder fs-8 fs-lg-base me-2 hover-elevate-up"
                      onClick={() => handleShowModal(audit.id)}
                    >
                      Supprimer
                      <i className="fa-solid fa-trash ms-2"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      
        ) : (
          <div className="text-center">Aucun audit réalisé</div>
        )}
        
        {/* Toast Notification */}
        <div id="kt_docs_toast_stack_container" className="toast-container position-fixed top-0 end-0 p-3 z-index-3">
          <div id="deleteToast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header bg-success text-white">
              <strong className="me-auto">Succès</strong>
              <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div className="toast-body bg-success text-white">
              L'audit a été supprimé avec succès.
            </div>
          </div>
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation de suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous sûr de vouloir supprimer cet audit ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Annuler
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>


      </div>
    </div>
  );
};

export default Audits;